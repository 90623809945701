<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'quantity-field',

    props: [
      'product'
    ],

    data() {
      return {
        quantity: (this.product.stock >= 4) ? 4 : this.product.stock
      }
    },

    watch: {
      'quantity': 'quantityChanged'
    },

    methods: {
      quantityChanged() {
        if (this.quantity != "" && this.quantity < 1) {
          this.quantity = 1;
        }

        if (this.product.stock < this.quantity) {
          this.quantity = this.product.stock;
        }

        this.$root.$emit('quantityChanged', this.product, this.quantity);
      },
    }
  };
</script>

<template>
  <div>
    <span class="antal-label">Antal</span> <input v-model="quantity" class="qty">
  </div>
</template>
