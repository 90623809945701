<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import {mapState, mapMutations} from 'vuex';

  import SelectButton from '../SelectButton';



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'rim-product-thumb',

    components: {
      SelectButton
    },

    props: [
      'product'
    ],

    mounted() {
      console.log("Product: ", this.product);
    },

    computed: {
      shownPrice() {
        return Math.round(this.product.consumerPrice / 100.0 * 1.25);
      },

      productURL() {
        return '/produkter/falg/' + this.product.slug;
      }
    }
  };
</script>

<template>
  <div class="col-12 col-md-3 col-lg-3 mb-30">
    <article class="product-card rims bg-white text-center p-3 mb-5">
      <div v-if="product.image" class="product-image"><img :src="product.image.original" width="auto" height="250" alt="Image Description"></div>
      <div v-if="!product.image" class="product-image"><img src="/images/no-wheel.png" width="auto" height="250" alt=""></div>
      <h4 class="mb-10"><a :href="productURL" data-abc="true">{{ product.name }}</a></h4>
      <p v-if="product.condition.type == 'USED'">Begagnat</p>
      <span class="price">{{ shownPrice }} kr/st</span>
      <div v-if="product.stock <= 0" class="stock">Lagerstatus: <span class="outstock">produkten finns ej lager</span></div>
      <div v-if="product.stock < 4 && product.stock > 0" class="stock">Lagerstatus: <span class="instock">{{ product.stock }} i lager</span></div>
      <div v-if="product.stock >= 4 && product.source == 'supplier'" class="stock">Lagerstatus: <span class="instock">5-7 dagar</span></div>
      <div v-if="product.stock >= 4 && product.source != 'supplier'" class="stock">Lagerstatus: <span class="instock">produkten finns i lager</span></div>
      <select-button type="rims" :product="product"></select-button>
    </article>
  </div>
</template>
