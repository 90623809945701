<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import {mapState, mapMutations} from 'vuex';



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'size-button',

    props: [
      'type',
      'car',
      'index',
      'size',
      'tyreType',
      'selected'
    ],

    mounted() {
      console.log("Size: ", this.size);
    },

    computed: {
      buttonClass() {
        var text = 'btn btn-primary mr-2';

        if (this.selected) {
          text = text + ' active';
        }

        return text;
      },

      sizeText() {
        if (this.type == 'tyres') {
          return this.size.text;
        }

        return this.size.diameter;
      },

      buttonURL() {
        if (this.type == 'tyres') {
          var url = '/dack/' + this.car.licenseplate + '/' + this.index + '/' + this.tyreType + '?page=1';

          return url;
        }

        var url = '/falgar/' + this.car.licenseplate + '/' + this.index + '?page=1';

        return url;
      },

      ...mapState({
        cart: state => state.cart
      })
    }
  };
</script>

<template>
  <a :href="buttonURL" :class="buttonClass" role="button" aria-pressed="true">{{ sizeText }}</a>
</template>
