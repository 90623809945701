<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import {mapState, mapMutations} from 'vuex';

  import SizeButton from './components/SizeButton';



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'search-bar',

    components: {
      SizeButton
    },

    props: [
      'width',
      'diameter',
      'aspectRatio',
      'tyreType',
      'brandID',
      'brands',
      'showDimensions'
    ],

    data() {
      return {
        selectedWidth: 1,
        selectedDiameter: 1,
        selectedAspectRatio: 1,
        selectedTyreType: 1,
        selectedBrandID: 0,
      }
    },

    mounted() {
      this.selectedWidth = this.width;
      this.selectedDiameter = this.diameter;
      this.selectedAspectRatio = this.aspectRatio;
      this.selectedTyreType = this.tyreType;
      this.selectedBrandID = this.brandID;

      if (parseInt(this.showDimensions) == 1) {
        this.showDimensionDropdown();
      }
    },

    computed: {
      searchURL() {
        var url = '/dack/dimensioner/' + this.selectedWidth + '/' + this.selectedDiameter  + '/' + this.selectedAspectRatio + '/' + this.selectedTyreType + '/' + this.selectedBrandID + '/0?page=1';

        return url;
      },

      ...mapState({
        cart: state => state.cart
      })
    },

    methods: {
       selectWidth(width) {
        this.selectedWidth = width;
      },

      selectAspectRatio(aspectRatio) {
        this.selectedAspectRatio = aspectRatio;
      },

      selectDiameter(diameter) {
        this.selectedDiameter = diameter;

        this.hideDimensions();
      },

      tyreTypeChanged() {
      },

      selectBrand(brandID) {
        this.selectedBrandID = brandID;
      },

      search() {
        window.location.assign(this.searchURL);
      },

      showDimensionDropdown() {
        // let title = this.$refs.dimensiontitle;
        // let menu = this.$refs.dimensionmenu;

        // title.classList.add('show');
        // menu.classList.add('show');
      },

      hideDimensions() {
        let title = this.$refs.dimensiontitle;
        let menu = this.$refs.dimensionmenu;

        title.classList.remove('show');
        menu.classList.remove('show');
      }
    }
  };
</script>

<template>
<div>

	<!-- new filters -->

	<div class="container filter-container">
		<nav class="navbar navbar-expand-lg filter">
		<div class="container-fluid">
			<div class="collapse navbar-collapse visible" id="filter_nav">
				<div class="navbar-nav row">
					<div class="nav-item width dropdown" @click="hideDimensions">
						<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
							<span class="search-title">Bredd</span>
              <span class="search-car" v-if="selectedWidth == 145">145</span>
              <span class="search-car" v-if="selectedWidth == 155">155</span>
              <span class="search-car" v-if="selectedWidth == 165">165</span>
              <span class="search-car" v-if="selectedWidth == 175">175</span>
              <span class="search-car" v-if="selectedWidth == 185">185</span>
              <span class="search-car" v-if="selectedWidth == 195">195</span>
              <span class="search-car" v-if="selectedWidth == 205">205</span>
              <span class="search-car" v-if="selectedWidth == 215">215</span>
              <span class="search-car" v-if="selectedWidth == 225">225</span>
              <span class="search-car" v-if="selectedWidth == 235">235</span>
              <span class="search-car" v-if="selectedWidth == 245">245</span>
              <span class="search-car" v-if="selectedWidth == 255">255</span>
              <span class="search-car" v-if="selectedWidth == 265">265</span>
              <span class="search-car" v-if="selectedWidth == 275">275</span>
              <span class="search-car" v-if="selectedWidth == 285">285</span>
              <span class="search-car" v-if="selectedWidth == 295">295</span>
              <span class="search-car" v-if="selectedWidth == 305">305</span>
              <span class="search-car" v-if="selectedWidth == 315">315</span>
              <span class="search-car" v-if="selectedWidth == 325">325</span>
              <span class="search-car" v-if="selectedWidth == 335">335</span>
              <span class="search-car" v-if="selectedWidth == 345">345</span>
              <span class="search-car" v-if="selectedWidth == 355">355</span>
						</a>
						<div class="dropdown-menu" role="menu">
							<a class="dropdown-item" @click="selectWidth(145)">145</a>
              <a class="dropdown-item" @click="selectWidth(155)">155</a>
              <a class="dropdown-item" @click="selectWidth(165)">165</a>
              <a class="dropdown-item" @click="selectWidth(175)">175</a>
              <a class="dropdown-item" @click="selectWidth(185)">185</a>
              <a class="dropdown-item" @click="selectWidth(195)">195</a>
              <a class="dropdown-item" @click="selectWidth(205)">205</a>
              <a class="dropdown-item" @click="selectWidth(215)">215</a>
              <a class="dropdown-item" @click="selectWidth(225)">225</a>
              <a class="dropdown-item" @click="selectWidth(235)">235</a>
              <a class="dropdown-item" @click="selectWidth(245)">245</a>
              <a class="dropdown-item" @click="selectWidth(255)">255</a>
              <a class="dropdown-item" @click="selectWidth(265)">265</a>
              <a class="dropdown-item" @click="selectWidth(275)">275</a>
              <a class="dropdown-item" @click="selectWidth(285)">285</a>
              <a class="dropdown-item" @click="selectWidth(295)">295</a>
              <a class="dropdown-item" @click="selectWidth(305)">305</a>
              <a class="dropdown-item" @click="selectWidth(315)">315</a>
              <a class="dropdown-item" @click="selectWidth(325)">325</a>
              <a class="dropdown-item" @click="selectWidth(335)">335</a>
              <a class="dropdown-item" @click="selectWidth(345)">345</a>
              <a class="dropdown-item" @click="selectWidth(355)">355</a>
						</div> <!-- dropdown-mega-menu.// -->
					</div>
					<div class="nav-item profile dropdown" @click="hideDimensions">
						<a class="nav-link dropdown-toggle" ref="dimensiontitle" href="#" data-bs-toggle="dropdown">
							<span class="search-title">Profil</span>
              <span class="search-car" v-if="selectedAspectRatio == 20">20</span>
              <span class="search-car" v-if="selectedAspectRatio == 25">25</span>
              <span class="search-car" v-if="selectedAspectRatio == 30">30</span>
              <span class="search-car" v-if="selectedAspectRatio == 35">35</span>
              <span class="search-car" v-if="selectedAspectRatio == 40">40</span>
              <span class="search-car" v-if="selectedAspectRatio == 45">45</span>
              <span class="search-car" v-if="selectedAspectRatio == 50">50</span>
              <span class="search-car" v-if="selectedAspectRatio == 55">55</span>
              <span class="search-car" v-if="selectedAspectRatio == 60">60</span>
              <span class="search-car" v-if="selectedAspectRatio == 65">65</span>
              <span class="search-car" v-if="selectedAspectRatio == 70">70</span>
              <span class="search-car" v-if="selectedAspectRatio == 75">75</span>
              <span class="search-car" v-if="selectedAspectRatio == 80">80</span>
              <span class="search-car" v-if="selectedAspectRatio == 85">85</span>
						</a>
						<div class="dropdown-menu" ref="dimensionmenu" role="menu">
							<a class="dropdown-item" @click="selectAspectRatio(20)">20</a>
							<a class="dropdown-item" @click="selectAspectRatio(25)">25</a>
							<a class="dropdown-item" @click="selectAspectRatio(30)">30</a>
							<a class="dropdown-item" @click="selectAspectRatio(35)">35</a>
							<a class="dropdown-item" @click="selectAspectRatio(40)">40</a>
							<a class="dropdown-item" @click="selectAspectRatio(45)">45</a>
							<a class="dropdown-item" @click="selectAspectRatio(50)">50</a>
							<a class="dropdown-item" @click="selectAspectRatio(55)">55</a>
							<a class="dropdown-item" @click="selectAspectRatio(60)">60</a>
							<a class="dropdown-item" @click="selectAspectRatio(65)">65</a>
							<a class="dropdown-item" @click="selectAspectRatio(70)">70</a>
							<a class="dropdown-item" @click="selectAspectRatio(75)">75</a>
							<a class="dropdown-item" @click="selectAspectRatio(80)">80</a>
							<a class="dropdown-item" @click="selectAspectRatio(85)">85</a>
						</div> <!-- dropdown-mega-menu.// -->
					</div>
					<div class="nav-item inch dropdown">
						<a class="nav-link dropdown-toggle" ref="dimensiontitle" href="#" data-bs-toggle="dropdown">
							<span class="search-title">Tum</span>
              <span class="search-car" v-if="selectedDiameter == 13">13"</span>
              <span class="search-car" v-if="selectedDiameter == 14">14"</span>
              <span class="search-car" v-if="selectedDiameter == 15">15"</span>
              <span class="search-car" v-if="selectedDiameter == 16">16"</span>
              <span class="search-car" v-if="selectedDiameter == 17">17"</span>
              <span class="search-car" v-if="selectedDiameter == 18">18"</span>
              <span class="search-car" v-if="selectedDiameter == 19">19"</span>
              <span class="search-car" v-if="selectedDiameter == 20">20"</span>
              <span class="search-car" v-if="selectedDiameter == 21">21"</span>
              <span class="search-car" v-if="selectedDiameter == 22">22"</span>
              <span class="search-car" v-if="selectedDiameter == 23">23"</span>
              <span class="search-car" v-if="selectedDiameter == 24">24"</span>
              <span class="search-car" v-if="selectedDiameter == 25">25"</span>
						</a>
						<div class="dropdown-menu" role="menu" ref="dimensionmenu">
							<a class="dropdown-item" @click="selectDiameter(13)">13"</a>
              <a class="dropdown-item" @click="selectDiameter(14)">14"</a>
							<a class="dropdown-item" @click="selectDiameter(15)">15"</a>
							<a class="dropdown-item" @click="selectDiameter(16)">16"</a>
							<a class="dropdown-item" @click="selectDiameter(17)">17"</a>
							<a class="dropdown-item" @click="selectDiameter(18)">18"</a>
							<a class="dropdown-item" @click="selectDiameter(19)">19"</a>
							<a class="dropdown-item" @click="selectDiameter(20)">20"</a>
							<a class="dropdown-item" @click="selectDiameter(21)">21"</a>
							<a class="dropdown-item" @click="selectDiameter(22)">22"</a>
							<a class="dropdown-item" @click="selectDiameter(23)">23"</a>
							<a class="dropdown-item" @click="selectDiameter(24)">24"</a>
							<a class="dropdown-item" @click="selectDiameter(25)">25"</a>
						</div> <!-- dropdown-mega-menu.// -->
					</div>
					<div class="nav-item season dropdown" @click="hideDimensions">
						<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
							<span class="search-title">Typ / Säsong</span>
							<span class="search-car" v-if="selectedTyreType == 1">Sommardäck</span>
							<span class="search-car" v-if="selectedTyreType == 2">Vinterdäck med dubb</span>
							<span class="search-car" v-if="selectedTyreType == 3">Vinterdäck</span>
							<span class="search-car" v-if="selectedTyreType == 4">Åretruntdäck</span>
						</a>
						<div class="dropdown-menu megamenu" role="menu">
							<a class="dropdown-item" @click="selectedTyreType = 1; tyreTypeChanged();">Sommardäck</a>
							<a class="dropdown-item" @click="selectedTyreType = 2; tyreTypeChanged();">Vinterdäck med dubb</a>
							<a class="dropdown-item" @click="selectedTyreType = 3; tyreTypeChanged();">Vinterdäck</a>
							<a class="dropdown-item" @click="selectedTyreType = 4; tyreTypeChanged();">Åretruntdäck</a>
						</div> <!-- dropdown-mega-menu.// -->
					</div>
					<div class="nav-item brand dropdown has-megamenu" @click="hideDimensions">
						<a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
							<span class="search-title">Märke</span>
              <span v-for="brand in brands" class="search-car" v-if="selectedBrandID == brand.id">{{ brand.name }}</span>
						</a>
						<div class="dropdown-menu megamenu" role="menu">
							<div class="row">
								<div class="col-12 col-md-2 text-center text-light" v-for="brand in brands">
									<a class="dropdown-item" @click="selectBrand(brand.id)">{{ brand.name }}</a>
								</div>
							</div>
						</div> <!-- dropdown-mega-menu.// -->
					</div>
					<div class="nav-item button text-end">
          <a class="search-button" style="top: -13px; position: relative;" @click="search">Sök</a>
        </div>
				</div>

			</div> <!-- navbar-collapse.// -->
		</div> <!-- container-fluid.// -->
		</nav>

	</div>

</div>
</template>
