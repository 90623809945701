<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import {mapState, mapMutations} from 'vuex';



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'cart-button',

    mounted() {
      if (localStorage.cart_items == null) {
        this.setItems([]);
      } else {
        this.setItems(JSON.parse(localStorage.cart_items));
      }
    },

    computed: {
      ...mapState({
        cart: state => state.cart
      })
    },

    methods: {
      ...mapMutations('cart', [
        'setItems',
      ])
    }
  };
</script>

<template>
  <div class="header-cart">
    <a href="/butik/varukorg"><button type="button" class="btn addtocart">Varukorg ( {{ cart.items.length }} produkter )</button></a>
  </div>
</template>
