<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import {mapState, mapMutations} from 'vuex';

  import Swal from 'sweetalert2';


  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'add-button',

    props: [
      'rim',
      'tyre'
    ],

    data() {
      return {
        quantity: 4
      }
    },

    computed: {
      ...mapState({
        cart: state => state.cart
      })
    },

    methods: {
      addProducts() {
        this.addItem({
          product: this.rim,
          quantity: this.quantity,
          balancingState: 0
        });

        this.addItem({
          product: this.tyre,
          quantity: this.quantity,
          balancingState: 0
        });

        Swal.fire({
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            text: 'Produkterna har lagts till i varukorgen'
          });
      },

      ...mapMutations('cart', [
        'addItem'
      ])
    }
  };
</script>

<template>
  <div class="add-button">
    <button @click="addProducts" type="button" class="btn addtocart">Lägg i varukorgen</button>
  </div>
</template>

<style scoped lang="scss">

  /*
  |--------------------------------------------------------------------------
  | Add Button
  |--------------------------------------------------------------------------
  */

  .add-button {
  }
</style>
