
/*
|--------------------------------------------------------------------------
| Dependency Definitions
|--------------------------------------------------------------------------
*/

import cart from './modules/cart';



/*
|--------------------------------------------------------------------------
| Store Implementation
|--------------------------------------------------------------------------
*/

export default {
  modules: {
    cart: cart
  }
};
