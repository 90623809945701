<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import {mapState, mapMutations} from 'vuex';

  import SizeButton from './components/SizeButton';
  import SizeColumn from './components/SizeColumn';



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'search-bar',

    components: {
      SizeButton,
      SizeColumn
    },

    props: [
      'type',
      'car',
      'currentSize',
      'tyreType',
      'brandID',
      'brands',
      'showDimensions'
    ],


    mounted() {
      this.selectedTyreType = this.tyreType;
      this.selectedBrandID = this.brandID;

      if (parseInt(this.showDimensions) == 1) {
        this.showDimensionDropdown();
      }
    },

    data() {
      return {
        selectedTyreType: 1,
        selectedBrandID: 0,

        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    },

    computed: {
      searchURL() {
        var url = '/dack/' + this.car.licenseplate + '/' + this.currentSize + '/' + this.selectedTyreType + '/' + this.selectedBrandID + '/0?page=1';

        return url;
      },

      tyresURL() {
        if (this.type == 'tyres') {
          return '/dack/' + this.car.licenseplate + '/' + this.currentSize + '/' + this.selectedTyreType + '/' + this.selectedBrandID + '/0?page=1';
        }

        return '/dack/' + this.car.licenseplate + '/' + this.currentSize + '/1/0/0?page=1';
      },

      rimsURL() {
        if (this.type == 'tyres') {
          return '/falgar/' + this.car.licenseplate + '/' + this.currentSize + '/0/0?page=1';
        }

        return '/falgar/' + this.car.licenseplate + '/' + this.currentSize + '/' + this.selectedBrandID + '/0?page=1';
      },

      shownRimSizes() {
        var shownRims = [];
        var shownSizes = [];

        for (var i = 0; i < this.car.rimSizes.length; i++) {
          let currentSize = this.car.rimSizes[i];

          if (shownSizes.indexOf(parseInt(currentSize.diameter)) == -1) {
            shownSizes.push(parseInt(currentSize.diameter));

            shownRims.push(currentSize);
          }
        }

        return shownRims;
      },

      columnClass() {
        if (this.type == 'tyres') {
          return 'col-12 col-md-2 border-end';
        }

        return 'col-12 col-md-3 border-end';
      },

      sizeColumns() {
        var currentColumns = [];
        var shownSizes = [];

        if (this.type == 'tyres') {
          for (var i = 0; i < this.car.tyreSizes.length; i++) {
            let currentSize = this.car.tyreSizes[i];

            if (shownSizes.indexOf(parseInt(currentSize.diameter)) == -1) {
              shownSizes.push(parseInt(currentSize.diameter));

              currentSize.index = i;

              currentColumns.push({
                diameter: currentSize.diameter,
                sizes: [currentSize]
              });
            } else {
              for (var j = 0; j < currentColumns.length; j++) {
                if (currentColumns[j].diameter == currentSize.diameter) {
                  currentSize.index = i;

                  currentColumns[j].sizes.push(currentSize);
                }
              }
            }
          }
        }

        if (this.type == 'rims') {
          for (var i = 0; i < this.car.rimSizes.length; i++) {
            let currentSize = this.car.rimSizes[i];

            if (shownSizes.indexOf(parseInt(currentSize.diameter)) == -1) {
              shownSizes.push(parseInt(currentSize.diameter));

              currentSize.index = i;

              currentColumns.push({
                diameter: currentSize.diameter,
                sizes: [currentSize]
              });
            }
          }
        }

        return currentColumns;
      },

      ...mapState({
        cart: state => state.cart
      })
    },

    methods: {
      tyreTypeChanged() {
        window.location.assign(this.searchURL);
      },

      selectBrand(brandID) {
        this.selectedBrandID = brandID;

        if (this.type == 'tyres') {
          window.location.assign(this.searchURL);
        } else {
          window.location.assign(this.rimsURL);
        }
      },

      showDimensionDropdown() {
        let title = this.$refs.dimensiontitle;
        let menu = this.$refs.dimensionmenu;

        title.classList.add('show');
        menu.classList.add('show');
      },

      hideDimensions() {
        let title = this.$refs.dimensiontitle;
        let menu = this.$refs.dimensionmenu;

        title.classList.remove('show');
        menu.classList.remove('show');
      }
    }
  };
</script>

<template>
  <div>

	<!-- new filters -->

	<div class="container filter-container">
	<nav class="navbar navbar-expand-lg filter">
	<div class="container-fluid">
		<div class="collapse navbar-collapse visible" id="filter_nav">
			<div class="navbar-nav row">
				<div class="nav-item chosen dropdown" @click="hideDimensions">
					<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
						<span class="search-title">Din valda bil:</span>
            <span class="search-car">{{ car.licenseplate }} {{ car.brand.name }} {{ car.model.name }} {{ car.year }}</span>
					</a>
					<div class="dropdown-menu" role="menu">
						<h5 class="text-white">Sök på annan bil</h5>
              <form method="POST" action="/search/licenseplate">
                <input type="hidden" name="_token" :value="csrf">
                <input type="text" id="license_plate" name="license_plate" class="searchBig" placeholder="ABC 123"
                  onkeyup="var start = this.selectionStart; var end = this.selectionEnd; this.value = this.value.toUpperCase(); this.setSelectionRange(start, end);">
                <h5 class="pt-4 text-white text-center">Välj produkt</h5>
                  <button type="submit" name="tyres" value="tyres" class="searchButton">Däck</button>
                  <button type="submit" name="rims" value="rims" class="searchButton">Fälg</button>
              </form>
					</div> <!-- dropdown-mega-menu.// -->
				</div>
				<div class="nav-item dimension dropdown has-megamenu">
					<a class="nav-link dropdown-toggle" ref="dimensiontitle" href="#" data-bs-toggle="dropdown">
						 <span class="search-title" v-if="type == 'tyres'">Dimension</span><span class="search-car" v-if="type == 'tyres'">{{ car.tyreSizes[currentSize].text }}</span>
            <span class="search-title" v-if="type == 'rims'">Dimension</span><span class="search-car" v-if="type == 'rims'">{{ car.rimSizes[currentSize].diameter }}"</span>
					</a>
					<div class="dropdown-menu megamenu" ref="dimensionmenu" role="menu">
						<div class="row">
							<h4 class="text-white text-center p-0 m-0 pb-4 pb-md-0">Välj storlek</h4>
              <size-column
                v-if="type == 'tyres'"
                v-for="(column, index) in sizeColumns"
                type="tyres"
                :car="car"
                :column="column"
                :tyre-type="selectedTyreType"
                :brand-i-d="selectedBrandID"
                :key="column.diameter"
              ></size-column>

              <size-column
                v-if="type == 'rims'"
                v-for="(column, index) in sizeColumns"
                type="rims"
                :car="car"
                :column="column"
                :tyre-type="selectedTyreType"
                :brand-i-d="selectedBrandID"
                :key="column.diameter"
              ></size-column>
            </div>
					</div> <!-- dropdown-mega-menu.// -->
				</div>
				<div class="nav-item search-content dropdown" @click="hideDimensions">
					<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
						<span class="search-title" v-if="type == 'tyres'">Sök efter</span><span class="search-car" v-if="type == 'tyres'">Däck</span>
            <span class="search-title" v-if="type == 'rims'">Sök efter</span><span class="search-car" v-if="type == 'rims'">Fälg</span>
					</a>
					<div class="dropdown-menu" role="menu">
						<a class="dropdown-item" :href="tyresURL">Däck</a>
            <a class="dropdown-item" :href="rimsURL">Fälg</a>
					</div> <!-- dropdown-mega-menu.// -->
				</div>
				<div class="nav-item season dropdown" v-if="type == 'tyres'" @click="hideDimensions">
					<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
						<span class="search-title">Typ / Säsong</span>
            <span class="search-car" v-if="selectedTyreType == 1">Sommardäck</span>
            <span class="search-car" v-if="selectedTyreType == 2">Vinterdäck med dubb</span>
            <span class="search-car" v-if="selectedTyreType == 3">Vinterdäck</span>
            <span class="search-car" v-if="selectedTyreType == 4">Åretruntdäck</span>
					</a>
					<div class="dropdown-menu megamenu" role="menu">
						<a class="dropdown-item" @click="selectedTyreType = 1; tyreTypeChanged();">Sommardäck</a>
            <a class="dropdown-item" @click="selectedTyreType = 2; tyreTypeChanged();">Vinterdäck med dubb</a>
            <a class="dropdown-item" @click="selectedTyreType = 3; tyreTypeChanged();">Vinterdäck</a>
            <a class="dropdown-item" @click="selectedTyreType = 4; tyreTypeChanged();">Åretruntdäck</a>
					</div> <!-- dropdown-mega-menu.// -->
				</div>
				<div class="nav-item brand dropdown has-megamenu" @click="hideDimensions">
					<a class="nav-link dropdown-toggle" v-for="brand in brands" v-if="selectedBrandID == brand.id" data-bs-toggle="dropdown"><span class="search-title">Märke:</span> <span class="search-car">{{ brand.name }}</span></a>
					<div class="dropdown-menu megamenu" role="menu">
						<div class="row">
              <div class="col-12 col-md-2 text-center text-light" v-for="brand in brands">
                <button @click="selectBrand(brand.id)" class="filter-button">{{ brand.name }}</button>
              </div>
						</div>
					</div> <!-- dropdown-mega-menu.// -->
				</div>
			</div>

		</div> <!-- navbar-collapse.// -->
	</div> <!-- container-fluid.// -->
	</nav>

</div>

</div>
</template>
