<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import {mapState, mapMutations} from 'vuex';



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'size-button',

    props: [
      'type',
      'car',
      'size',
      'tyreType',
      'brandID'
    ],

    computed: {
      buttonClass() {
        var text = 'btn btn-primary mr-2';

        if (this.selected) {
          text = text + ' active';
        }

        return text;
      },

      sizeText() {
        if (this.type == 'tyres') {
          return this.size.text;
        }

        return this.size.diameter + '"';
      },

      ...mapState({
        cart: state => state.cart
      })
    },

    methods: {
      changeSize() {
        if (this.type == 'tyres') {
          this.$root.$emit('tyreSizeChanged', this.size.index);
        }

        if (this.type == 'rims') {
          this.$root.$emit('rimSizeChanged', this.size.index);
        }
      },
    }
  };
</script>

<template>
  <a @click="changeSize"><button class="filter-button">{{ sizeText }}</button></a>
</template>
