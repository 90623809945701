<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import {mapState, mapMutations} from 'vuex';



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'pagination',

    props: [
      'pagination'
    ],

    computed: {
      previousClass() {
        return this.pagination.previousLink.disabled;
      },

      nextClass() {
        return this.pagination.nextLink.disabled;
      }
    },

    methods: {
      changePage(page) {
        console.log("changePage: ", page);

        this.$root.$emit('changePage', page);
      }
    }
  };
</script>

<template>
  <nav aria-label="Page navigation">
    <ul class="pagination">
      <li :class="previousClass">
        <a class="page-link" @click="changePage(pagination.previousLink.page)" aria-label="Föregående">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>

      <!-- @foreach ($pagination['links'] as $paginationLink)
        <li class="page-item {{ $paginationLink['disabled'] }}"><a class="page-link" href="{{ $paginationLink['link'] }}">{{ $paginationLink['page_no'] }}</a></li>
      @endforeach -->

      <li v-for="paginationLink in pagination.links" :class="paginationLink.disabled"><a class="page-link" @click="changePage(paginationLink.page)">{{ paginationLink.page_no }}</a></li>

      <li :class="nextClass">
        <a class="page-link" @click="changePage(pagination.nextLink.page)" aria-label="Nästa">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>
