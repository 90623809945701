<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import {mapState, mapMutations} from 'vuex';

  import SelectButton from '../SelectButton';



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'tyre-product',

    components: {
      SelectButton
    },

    props: [
      'product',
      'tyreType'
    ],

    computed: {
      shownPrice() {
        return Math.round(this.product.consumerPrice / 100.0 * 1.25);
      },

      snowFlakeClass() {
        if (this.product.attrs.tyreType.id == 2) {
          return 'iconSun';
        } else {
          if (this.tyreType == 4) {
            return 'iconSnowSunThumb';
          } else {
            if (this.product.attrs.tyreType.id == 1) {
             return 'iconSnowSunThumb';
            } else {
              return 'iconSnowThumb';
            }
          }
        }
      },

      productURL() {
        return '/produkter/dack/' + this.product.slug;
      }
    }
  };
</script>

<template>
  <div class="card card-body mb-4">
    <div class="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row">
      <div v-if="product.brand_logo_url" class="brand-image"><img :src="product.brand_logo_url" width="auto" height="40" alt="Image Description"></div>
      <div v-if="product.image" class="product-image"><img :src="product.image.original" width="auto" height="250" alt="Image Description"></div>
      <div v-if="!product.image" class="product-image"><img src="/images/no-wheel.png" width="auto" height="250" alt=""></div>
      <div class="media-body mt-2">
        <h6 class="product-title font-weight-semibold"><a :href="productURL" data-abc="true">{{ product.name }}</a></h6>
        <p v-if="product.condition.type == 'USED'">Begagnat</p>
        <div class="iconGas"><span>{{ product.attrs.rollingResistance }}</span></div>
        <div class="iconRain"><span>{{ product.attrs.wetGrip }}</span></div>
        <div class="iconVol"><span>{{ product.attrs.noiseDecibel }} DB</span></div>
        <div :class="snowFlakeClass"><span></span></div>
        <ul class="list-unstyled row pt-2 pr-0 pr-md-3 pr-lg-3">
          <li class="list-item col-3 border-top py-2">Däckbredd:</li>
          <li class="list-item col-3 border-top py-2">{{ product.attrs.width }}</li>
          <li class="list-item col-3 border-top py-2">Säsong:</li>
          <li class="list-item col-3 border-top py-2">{{ product.attrs.tyreType.name }}</li>
          <li class="list-item col-3 border-top py-2">Däckprofil:</li>
          <li class="list-item col-3 border-top py-2">{{ product.attrs.aspectRatio }}</li>
          <li class="list-item col-3 border-top py-2">Art. nr:</li>
          <li class="list-item col-3 border-top py-2">{{ product.productId }}</li>
          <li class="list-item col-3 border-top py-2">Diameter:</li>
          <li class="list-item col-3 border-top py-2">{{ product.attrs.diameter }}</li>
          <li class="list-item col-3 border-top py-2">Hastighetsindex:</li>
          <li class="list-item col-3 border-top py-2">{{ product.attrs.speedIndex }}</li>
          <li class="list-item col-3 border-top py-2">&nbsp;</li>
          <li class="list-item col-3 border-top py-2">&nbsp;</li>
          <li class="list-item col-3 border-top py-2">Lastindex:</li>
          <li class="list-item col-3 border-top py-2">{{ product.attrs.loadIndex }}</li>
        </ul>
      </div>
      <div class="mt-3 mt-lg-3 ml-lg-3 text-center text-md-left">
        <h3 class="price">{{ shownPrice }} kr/st</h3>
        <div v-if="product.stock <= 0" class="stock">Lagerstatus: <span class="outstock">produkten finns ej lager</span></div>
        <div v-if="product.stock < 4 && product.stock > 0" class="stock">Lagerstatus: <span class="instock">{{ product.stock }} i lager</span></div>
        <div v-if="product.stock >= 4 && product.source == 'supplier'" class="stock">Lagerstatus: <span class="instock">5-7 dagar</span></div>
        <div v-if="product.stock >= 4 && product.source != 'supplier'" class="stock">Lagerstatus: <span class="instock">produkten finns i lager</span></div>
      <select-button type="tyres" :product="product"></select-button>
      </div>
    </div>
  </div>
</template>
