<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import {mapState, mapMutations} from 'vuex';

  import SizeButton from '../SizeButton';



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'size-column',

    components: {
      SizeButton
    },

    props: [
      'type',
      'car',
      'column',
      'tyreType',
      'brandID'
    ],

    computed: {
      ...mapState({
        cart: state => state.cart
      })
    }
  };
</script>

<template>
  <div class="col-12 col-md-3 text-center text-light">
    <h4 v-if="type == 'tyres'">{{ column.diameter }}"</h4>
    <size-button
      v-if="type == 'tyres'"
      v-for="(size, index) in column.sizes"
      type="tyres"
      :car="car"
      :size="size"
      :tyre-type="tyreType"
      :brand-i-d="brandID"
      :key="size.text"
    ></size-button>

    <size-button
      v-if="type == 'rims'"
      v-for="(size, index) in column.sizes"
      type="rims"
      :car="car"
      :size="size"
      :tyre-type="tyreType"
      :brand-i-d="brandID"
      :key="size.text"
    ></size-button>
  </div>
</template>
