<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import {mapState, mapMutations} from 'vuex';



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'rim-product',

    props: [
      'product'
    ],

    computed: {
      shownPrice() {
        return Math.round(this.product.consumerPrice / 100.0 * 1.25);
      },

      productURL() {
        return '/produkter/falg/' + this.product.slug;
      }
    }
  };
</script>

<template>
  <div class="card card-body mb-4">
    <div class="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row">
      <div v-if="product.image" class="product-image"><img :src="product.image.original" width="auto" height="250" alt=""></div>
      <div v-if="!product.image" class="product-image"><img src="/images/no-wheel.png" width="auto" height="250" alt=""></div>
      <div class="media-body mt-2">
        <h6 class="product-title font-weight-semibold"><a :href="productURL" data-abc="true">{{ product.name }}</a></h6>
        <p v-if="product.condition.type == 'USED'" class="underrubrik">Begagnat</p>
        <ul class="list-unstyled row pt-2 pr-5">
          <li class="list-item col-3 border-top py-2">Material:</li>
          <li class="list-item col-3 border-top py-2">{{ product.attrs.material.name }}</li>
          <li class="list-item col-3 border-top py-2">Märke:</li>
          <li class="list-item col-3 border-top py-2">{{ product.brand.name }}</li>
          <li class="list-item col-3 border-top py-2">Fälgbredd:</li>
          <li class="list-item col-3 border-top py-2">{{ product.attrs.width }}</li>
          <li class="list-item col-3 border-top py-2">Centrumhål:</li>
          <li class="list-item col-3 border-top py-2">{{ product.attrs.centerBore }}</li>
          <li class="list-item col-3 border-top py-2">Bultmönster:</li>
          <li class="list-item col-3 border-top py-2">{{ product.attrs.bolt1 }}</li>
          <li class="list-item col-3 border-top py-2">Offset / ET:</li>
          <li class="list-item col-3 border-top py-2">{{ product.attrs.etOffset }}</li>
        </ul>
      </div>

      <div class="mt-3 mt-lg-3 ml-lg-3 text-left">
        <h3 class="price">{{ shownPrice }} kr/st</h3>
        <div v-if="product.stock <= 0" class="stock">Lagerstatus: <span class="outstock">produkten finns ej lager</span></div>
        <div v-if="product.stock < 4 && product.stock > 0" class="stock">Lagerstatus: <span class="instock">{{ product.stock }} i lager</span></div>
        <div v-if="product.stock >= 4 && product.source == 'supplier'" class="stock">Lagerstatus: <span class="instock">5-7 dagar</span></div>
        <div v-if="product.stock >= 4 && product.source != 'supplier'" class="stock">Lagerstatus: <span class="instock">produkten finns i lager</span></div>
        <!-- <add-button :product="{{ json_encode($product) }}"></add-button> -->
      </div>
    </div>
  </div>
</template>
