<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import {mapState, mapMutations} from 'vuex';

  import Swal from 'sweetalert2';


  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'select-button',

    props: [
      'type',
      'product'
    ],

    mounted() {
    },

    data() {
      return {
        quantity: 4
      }
    },

    computed: {
      ...mapState({
        cart: state => state.cart
      })
    },

    methods: {
      selectProduct() {
        if (this.type == 'rims') {
          this.$root.$emit('selectRim', this.product);
        }

        if (this.type == 'tyres') {
          this.$root.$emit('selectTyre', this.product);
        }

        window.scrollTo(0, 0);
      },

      ...mapMutations('cart', [
        'addItem'
      ])
    }
  };
</script>

<template>
  <div class="add-button">
    <button @click="selectProduct" type="button" class="btn addtocart">Välj denna produkt</button>
  </div>
</template>
