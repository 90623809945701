<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import {mapState, mapMutations} from 'vuex';

  import SearchBar from './components/SearchBar';

  import RimProductThumb from './components/RimProductThumb';
  import RimProduct from './components/RimProduct';

  import TyreProductThumb from './components/TyreProductThumb';
  import TyreProduct from './components/TyreProduct';

  import AddButton from './components/AddButton';
  import Pagination from './components/Pagination';



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'rim-tyres',

    components: {
      SearchBar,
      RimProductThumb,
      RimProduct,
      TyreProductThumb,
      TyreProduct,
      AddButton,
      Pagination
    },

    props: [
      'licensePlate',
      'car'
    ],

    mounted() {
      this.fetchRims();

      this.$root.$on('selectRim', (product) => {
        this.selectedRim = product;

        this.state = 'tyres';
        this.brandID = 0;
        this.page = 1;

        this.fetchTyres();
      });

      this.$root.$on('selectTyre', (product) => {
        this.selectedTyre = product;
      });

      this.$root.$on('rimSizeChanged', (sizeIndex) => {
        this.rimSizeIndex = sizeIndex;

        this.fetchRims();
      });

      this.$root.$on('tyreSizeChanged', (sizeIndex) => {
        this.tyreSizeIndex = sizeIndex;

        this.fetchTyres();
      });

      this.$root.$on('tyreTypeChanged', (tyreType) => {
        this.tyreType = tyreType;

        this.fetchTyres();
      });

      this.$root.$on('brandChanged', (brandID) => {
        this.brandID = brandID;

        if (this.state == 'rims') {
          this.fetchRims();
        }

        if (this.state == 'tyres') {
          this.fetchTyres();
        }
      });

      this.$root.$on('changePage', (page) => {
        this.page = page;

        if (this.state == 'rims') {
          this.fetchRims();
        }

        if (this.state == 'tyres') {
          this.fetchTyres();
        }
      });
    },

    data() {
      return {
        state: 'rims',

        rimSizeIndex: 0,
        tyreSizeIndex: 0,
        tyreType: 1,
        brandID: 0,
        brands: [],
        page: 1,

        rims: [],
        selectedRim: null,
        loadingRims: false,

        tyres: [],
        selectedTyre: null,
        loadingTyres: false,

        pagination: null
      }
    },

    computed: {
      diameter() {
        return this.car.rimSizes[this.rimSizeIndex].diameter;
      },

      rimStepClass() {
        var currentClass = 'card p-3';

        return currentClass;
      },

      rimStepText() {
        if (this.selectedRim == null) {
          return 'Välj fälg nedan';
        }

        return this.selectedRim.name;
      },

      tyreStepClass() {
        var currentClass = 'card p-3';

        if (this.state == 'rims') {
          currentClass += ' unselected';
        }

        return currentClass;
      },

      reviewStepClass() {
        var currentClass = 'card p-3';

        if (this.selectedRim == null || this.selectedTyre == null) {
          currentClass += ' unselected';
        }

        return currentClass;
      },

      tyreStepText() {
        if (this.selectedTyre == null) {
          return 'Välj däck nedan';
        }

        return this.selectedTyre.name;
      },

      tyreSizeColumns() {
        var currentColumns = [];
        var shownSizes = [];

        if (this.type == 'tyres') {
          for (var i = 0; i < this.car.tyreSizes.length; i++) {
            let currentRimSize = this.car.tyreSizes[i];

            if (shownSizes.indexOf(parseInt(currentRimSize.diameter)) == -1) {
              shownSizes.push(parseInt(currentRimSize.diameter));

              currentRimSize.index = i;

              currentColumns.push({
                diameter: currentRimSize.diameter,
                sizes: [currentRimSize]
              });
            } else {
              for (var j = 0; j < currentColumns.length; j++) {
                if (currentColumns[j].diameter == currentRimSize.diameter) {
                  currentRimSize.index = i;

                  currentColumns[j].sizes.push(currentRimSize);
                }
              }
            }
          }
        }

        return currentColumns;
      },
    },

    methods: {
      fetchRims() {
        this.rims = [];
        this.pagination = null;

        var self = this;

        this.loadingRims = true;

        axios.post('/api/v1/search/rims', {
          license_plate: this.licensePlate,
          diameter: this.diameter,
          rim_center_bore: this.car.rimCenterBore,
          rim_et_offset_min: this.car.rimEtOffsetMin,
          rim_et_offset_max: this.car.rimEtOffsetMax,
          rim_bolt_pattern: this.car.rimBoltPattern,
          brand_id: this.brandID,
          page: this.page
        })
        .then(function (response) {
          self.loadingRims = false;
          self.brands = response.data.brands;
          self.rims = response.data.products;
          self.pagination = response.data.pagination;
          self.updateTyreSizeIndex();
        })
        .catch(function (error) {
          self.loadingRims = false;

          console.log("Error fetching rims: ", error);
        });
      },

      fetchTyres() {
        var self = this;

        this.tyres = [];
        this.pagination = null;

        this.loadingTyres = true;

        axios.post('/api/v1/search/tyres', {
          license_plate: this.licenseplate,
          width: this.car.tyreSizes[this.tyreSizeIndex].width,
          diameter: this.car.tyreSizes[this.tyreSizeIndex].diameter,
          aspect_ratio: this.car.tyreSizes[this.tyreSizeIndex].aspectRatio,
          brand_id: this.brandID,
          tyre_type: this.tyreType,
          page: this.page
        })
        .then(function (response) {
          self.loadingTyres = false;
          self.brands = response.data.brands;
          self.tyres = response.data.products;
          self.pagination = response.data.pagination;
        })
        .catch(function (error) {
          self.loadingTyres = false;

          console.log("Error fetching tyres: ", error);
        });
      },

      updateTyreSizeIndex() {
        for (var i = 0; i < this.car.tyreSizes.length; i++) {
          let size = this.car.tyreSizes[i];

          if (size.diameter == this.diameter) {
            this.tyreSizeIndex = i;
            break;
          }
        }
      },

      resetRim() {
        this.state = 'rims';

        this.rimSizeIndex = 0;
        this.tyreSizeIndex = 0;
        this.tyreType = 1;
        this.brandID = 0;
        this.brands = [];
        this.page = 1;

        this.rims = [];
        this.selectedRim = null;
        this.loadingRims = false;

        this.tyres = [];
        this.selectedTyre = null;
        this.loadingTyres = false;

        this.fetchRims();
      }
    }
  };
</script>

<template>
  <div>
    <div class="container">
      <div class="row hide-for-small">
        <search-bar :type="state" :car="car" :current-rim-size="rimSizeIndex" :current-tyre-size="tyreSizeIndex" :brand-i-d="brandID" :brands="brands" :tyre-type="tyreType"></search-bar>
      </div>
    </div>

    <div class="wheel-rim-list-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2>Komplett hjul <span>till {{ car.brand.name }} {{ car.model.name }} {{ car.year }}</span></h2>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 col-md-4">
            <div :class="rimStepClass">
              <div class="row">
                <div class="col-4">
                  <img v-if="selectedRim != null && selectedRim.image" :src="selectedRim.image.original">
                  <img v-if="selectedRim != null && !selectedRim.image" src="/images/no-wheel.png">
                  <img v-if="selectedRim == null" src="/images/no-wheel.png">
                </div>
                <div class="col-6">
                  <div class="title">
                    1. FÄLG
                  </div>
                  <div>{{ rimStepText }}</div>
                  <a v-if="selectedRim != null" @click="resetRim" class="m-r-1">Ändra</a>
                </div>
                <div class="col-2">
                  <div class="checked">
                    <img v-if="selectedRim != null" src="/images/check-green.svg">
                    <img v-if="selectedRim == null" src="/images/check-black.svg">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div :class="tyreStepClass">
              <div class="row">
                <div class="col-4">
                  <img v-if="selectedTyre != null && selectedTyre.image" :src="selectedTyre.image.original">
                  <img v-if="selectedTyre != null && !selectedTyre.image" src="/images/no-wheel.png">
                  <img v-if="selectedTyre == null" src="/images/no-wheel.png">
                </div>
                <div class="col-6">
                  <div class="title">
                    2. DÄCK
                  </div>
                  <div>{{ tyreStepText }}</div>
                </div>
                <div class="col-2">
                  <div class="checked">
                    <img v-if="selectedTyre != null" src="/images/check-green.svg">
                    <img v-if="selectedTyre == null" src="/images/check-black.svg">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div :class="reviewStepClass">
              <div class="row">
                <div class="col-4">
                  <img src="/images/cart-3d.png">
                </div>
                <div class="col-8">
                  <div class="title">
                    3. GRANSKA ORDER
                  </div>
                  <div v-if="selectedRim == null || selectedTyre == null">Ej klar för granskning</div>
                  <add-button v-if="selectedRim != null && selectedTyre != null" :rim="selectedRim" :tyre="selectedTyre"></add-button>
                </div>
								<!--
                <div class="col-2">
                  <div class="checked">
                    <img v-if="selectedRim != null && selectedTyre != null" src="/images/check-green.svg">
                    <img v-if="selectedRim == null || selectedTyre == null" src="/images/check-black.svg">
                  </div>
                </div>
								-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="state == 'rims'" class="product-list-bg">
      <div class="container">
        <div v-if="loadingRims" class="row">
          <div class="col-12">
            <h2 class="text-center">Söker fälgar...</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <ul class="nav nav-pills search justify-content-end">
              <li class="nav-item">
                <a href="#grid-view" class="nav-link grid-view active" data-toggle="tab"><span>Grid</span></a>
              </li>
              <li class="nav-item">
                <a href="#list-view" class="nav-link list-view" data-toggle="tab"><span>Lista</span></a>
              </li>
            </ul>

            <div class="row mt-5">
              <div class="tab-content">
                <!-- TAB FÖR GRID -->
                <div class="tab-pane fade show active" id="grid-view">
                  <div class="row">
                    <rim-product-thumb
                      v-for="(product, index) in rims"
                      :product="product"
                      :key="product.id"
                    ></rim-product-thumb>
                  </div>
                </div>
                <!-- TAB FÖR LISTA -->
                <div class="tab-pane fade" id="list-view">
                  <rim-product
                      v-for="(product, index) in rims"
                      :product="product"
                      :key="product.id"
                    ></rim-product>
                </div>
              </div>
            </div>

            <div class="row mt-5 mb-5 d-flex justify-content-center">
              <pagination v-if="pagination != null" :pagination="pagination"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="state == 'tyres'" class="product-list-bg">
      <div class="container ">
        <div v-if="loadingTyres" class="row">
          <div class="col-12">
            <h2 class="text-center">Söker däck...</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <ul class="nav nav-pills search justify-content-end">
              <li class="nav-item">
                <a href="#grid-view" class="nav-link grid-view active" data-toggle="tab"><span>Grid</span></a>
              </li>
              <li class="nav-item">
                <a href="#list-view" class="nav-link list-view" data-toggle="tab"><span>Lista</span></a>
              </li>
            </ul>
            <div class="row mt-5">
              <div class="tab-content">

                <!-- TAB FÖR GRID -->
                <div class="tab-pane fade show active" id="grid-view">
                  <div class="row">
                    <tyre-product-thumb
                      v-for="(product, index) in tyres"
                      :product="product"
                      :tyre-type="tyreType"
                      :key="product.id"
                    ></tyre-product-thumb>
                  </div>
                </div>

                <!-- TAB FÖR LISTA -->
                <div class="tab-pane fade" id="list-view">
                  <tyre-product
                    v-for="(product, index) in tyres"
                    :product="product"
                    :tyre-type="tyreType"
                    :key="product.id"
                  ></tyre-product>
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-5 d-flex justify-content-center">
              <pagination v-if="pagination != null" :pagination="pagination"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
