
/*
|--------------------------------------------------------------------------
| Dependency Definitions
|--------------------------------------------------------------------------
*/

require('../bootstrap');

import Vue from 'vue';
import Vuex from 'vuex';

import store from '../store/store/store';

Vue.use(Vuex);



/*
|--------------------------------------------------------------------------
| Global Components
|--------------------------------------------------------------------------
*/

Vue.component('quantity-field', require('./components/QuantityField').default);
Vue.component('add-button', require('./components/AddButton').default);
Vue.component('cart-button', require('./components/CartButton').default);
Vue.component('search-bar', require('./components/SearchBar').default);
Vue.component('search-bar-dimensions', require('./components/SearchBarDimensions').default);
Vue.component('rim-tyres', require('./components/RimTyres').default);



/*
|--------------------------------------------------------------------------
| Application
|--------------------------------------------------------------------------
*/

let app = new Vue({
  el: '#app',

  store: new Vuex.Store(store)
});

// filter button and overlay in mobile
$(document).ready(function(){
	// Add Active Class
		$(".search-icon").click(function(){
			$(".search-screen").addClass("active");
		});

	// Remove Active Class
	$(".close-icon").click(function(){
		$(".search-screen").removeClass("active");
	});
});

function showAlert(){
  if($("#myAlert").find("div#myAlert2").length==0){
    $("#myAlert").append("<div class='alert alert-success alert-dismissable' id='myAlert2'> <button type='button' class='close' data-dismiss='alert'  aria-hidden='true'>&times;</button> Success! message sent successfully.</div>");
  }
  $("#myAlert").css("display", "");
}
